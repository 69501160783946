<template>
  <RightSideBar
    v-if="isOpen"
    @submit="isOpen"
    @close="isOpen = false, $emit('close')"
    submit="Save"
    :close-button="true"
    :loading="isLoading"
    button-class="bg-dynamicBackBtn text-white"
    width="520px"
  >
    <template v-slot:title>
      <div class="flex justify-start items-center">
        <BackButton @onClick="isOpen = false, $emit('close')" variant="secondary" />
        <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
          Compensation Summary
        </span>
      </div>
    </template>
    <template>
      <div class="border-b border-dashed border-romanSilver mb-5">
        <div class="flex justify-between items-start w-full gap-2">
          <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
              <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
              <div v-else class="h-56 border rounded-md flex justify-center items-center">
               <span style="font-size:60px">
                 {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
               </span>
              </div>
            </div>
          <div class="w-8/12 flex flex-col justify-start items-start gap-2">
            <h4 class="font-bold text-lg leading-5 text-darkPurple">
              {{ employeeInfo.fname }} {{ employeeInfo.lname }}
            </h4>
            <div class="flex flex-col justify-start items-center w-full gap-2">
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Function:
                </p>
                <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                  {{ employeeInfo.function }}
                </p>
              </div>
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Designation:
                </p>
                <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                  {{ employeeInfo.designation }}
                </p>
              </div>
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Job Level:
                </p>
                <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                  <span v-if="employeeInfo.level">
                    {{ employeeInfo.level.name }}
                  </span>
                </p>
              </div>
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Office Location:
                </p>
                <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                  {{ employeeInfo.location }}
                </p>
              </div>
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Manager:
                </p>
                <template v-if="(employeeInfo.lineManager)">
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.lineManager.fname }} {{ employeeInfo.lineManager.lname }}
                  </p>
                </template>
                <p v-else>-</p>
              </div>
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Length of Service:
                </p>
                <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                  <span v-if="employeeInfo.yearsOfService">
                    {{ employeeInfo.yearsOfService }}
                  </span>
                </p>
              </div>
              <div class="flex justify-start items-center w-full">
                <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                  Last Performance Score:
                </p>
                <template v-if="employeeInfo.performanceScore">
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.performanceScore }}
                    <span class="text-red-400" v-if="employeeInfo.performanceScore <= 2">(Poor)</span>
                    <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 2 && employeeInfo.performanceScore < 4)">
                      (Average)
                    </span>
                    <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 4)">(Excellent)</span>
                  </p>
                </template>
                <p v-else>-</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template>
      <div class="grid grid-cols-2 gap-2 bg-ceruleanBlue px-5 py-5 rounded-md shadow divide-x">
        <p class="text-white font-semibold">
          <span v-if="employeeInfo.compaRatio">
            Compa-ratio = {{ employeeInfo.compaRatio.toFixed(2) }}%
          </span>
        </p>
        <p class="flex justify-center text-white font-semibold px-2 text-center">
          <span v-if="employeeInfo.compaRatio <= 80">Underpaid</span>
          <span v-if="employeeInfo.compaRatio >= 120">Overpaid</span>
          <span v-if="(employeeInfo.compaRatio > 80 && employeeInfo.compaRatio < 120)">Within Range</span>
        </p>
      </div>
    </template>
    <template>
      <div class="flex flex-col justify-start items-start gap-5 my-5">
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Work Location:
          </p>
          <p class="font-semibold text-sm leading-5 text-jet">
            <span v-if="employeeInfo.location">{{ employeeInfo.location }}</span>
            <span v-else> - </span>
          </p>
        </div>
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Employment Type:
          </p>
          <p v-if="employeeInfo.employee" class="font-semibold text-sm text-jet">
            <span v-if="employeeInfo.employee.employmentType" class="capitalize">
              {{ employeeInfo.employee.employmentType }}
            </span>
            <span v-else> - </span>
          </p>
        </div>
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Compensation Type:
          </p>
          <p v-if="employeeInfo.employee" class="font-semibold text-sm text-jet">
            <span v-if="employeeInfo.employee.payType" class="capitalize">
              {{ employeeInfo.employee.payType }}
            </span>
            <span v-else> - </span>
          </p>
        </div>
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Pay Grade:
          </p>
          <p class="font-semibold text-sm leading-5 text-jet">
            <span v-if="employeeInfo.paygrade" class="capitalize">
              {{ employeeInfo.paygrade.payGradeId }}
            </span>
            <span v-else> - </span>
          </p>
        </div>
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Salary Range:
          </p>
          <p class="font-semibold text-sm leading-5 text-jet">
            <span v-if="employeeInfo.paygrade">
              {{ convertToCurrency(employeeInfo.paygrade.rangeMinimum) }}
                -
              {{ convertToCurrency(employeeInfo.paygrade.rangeMaximum) }}
            </span>
            <span v-else> - </span>
          </p>
        </div>
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Current Annual Gross:
          </p>
          <p class="font-semibold text-sm leading-5 text-jet">
            <span v-if="employeeInfo.employee">
              {{ convertToCurrency(employeeInfo.employee.grossPay) }}
            </span>
            <span v-else> - </span>
          </p>
        </div>
        <div class="flex justify-between items-center w-full">
          <p class="font-semibold text-sm leading-5 text-romanSilver">
            Range Penetration:
          </p>
          <p class="font-semibold text-sm leading-5 text-jet">
            <span v-if="employeeInfo.rangePenetration">
              {{ employeeInfo.rangePenetration.toFixed(1) }}
            </span>
            <span v-else> - </span>
          </p>
        </div>
      </div>
    </template>
    <template>
      <div class="hover:bg-ghostWhite rounded-md w-36 cursor-pointer"
        @click="$emit('adjust-pay', { userId: payload.userId, paygradeId: payload.paygrade.id })">
        <div class="flex justify-center items-start gap-2 px-3 py-2">
          <Icon icon-name="edit" size="xs" class="text-flame" />
          <p class="font-semibold text-base leading-5 text-flame">
            Adjust Pay
          </p>
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import RightSideBar from "@/components/RightSideBar";
  import Icon from "@/components/Icon";

  export default {
    name: 'CompensationSummary',
    components: {
      BackButton,
      RightSideBar,
      Icon
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        employeeInfo: {},
        payload: {}
      }
    },
    methods: {
      async toggle(payload){
        this.payload = payload
        this.isOpen = true
        this.isLoading = true
        await this.getEmployeeCompensationSummary(payload)
        this.isLoading = false
      },
      async getEmployeeCompensationSummary(payload){
        await this.$_getEmployeeCompensationSummary({
          userId: payload.userId,
          paygradeId: payload.paygrade.id
        }).then(({ data }) => {
          this.employeeInfo = {
            ...data.employee,
            ...data.employee.paygrade,
            ...data.employee.userDetails,
          }
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
        })
      }
    },
  }
</script>

<style>
  .rightSidebar{
    width: 540px !important;
  }
</style>

